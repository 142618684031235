import { InteractionStatus, AuthenticationResult } from '@azure/msal-browser'
import { useMsal, useIsAuthenticated } from '@azure/msal-react'
import MenuIcon from '@mui/icons-material/Menu'
import WifiIcon from '@mui/icons-material/Wifi'
import {
  AppBar,
  Drawer,
  IconButton,
  Toolbar,
  Box,
  CircularProgress,
  Fade,
  useMediaQuery,
  Snackbar,
  Alert,
  Button,
} from '@mui/material'
import { green, red } from '@mui/material/colors'
import { Theme } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { NovuProvider, PopoverNotificationCenter, NotificationBell } from '@novu/notification-center'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useNavigate } from 'react-router-dom'

import { novuAppIdentifier } from './authConfig'
import { loginRequest } from './authConfig'
import { PopoverMenu } from './components/PopoverMenu'
import NeedHelp from './help/layout'
import PMPLogo from './logo.png'
import ProductsContainer from './products/ProductsContainer'
import WeDryLayout from './products/WeDry/Layout'
import WePlaneLayout from './products/WePlane/Layout'
import WeSawLayout from './products/WeSaw/Layout'
import WeScheduleLayout from './products/WeSchedule/Layout'
import WeTrackLayout from './products/WeTrack/Layout'
import ReportsContainer from './reports/ReportsContainer'
import ReportPlaceholder from './reports/viewer/ReportPlaceholder'
import ReportViewerWrapper from './reports/viewer/ReportViewerWrapper'
import {
  fetchNovuSubscriberInfo,
  fetchProductLicenses,
  fetchUserDetails,
  fetchOrganization,
  fetchAddressCandidate,
  updateUserLanguage,
} from './services/apiService'
import {
  setAuthenticated,
  setReady,
  setName,
  setAccessToken,
  setNovuSubscriberInfo,
  setProductLicenses,
  setUserDetails,
  setOrganization,
  setAddressCandidate,
  setWorkingAddress,
  clearAuth,
} from './store/authSlice'
import { useAppSelector, useAppDispatch } from './store/hooks'
import UserSettingsLayout from './user/Layout'

const useStyles = makeStyles((theme: Theme) => ({
  toolBar: {
    justifyContent: 'space-between',
  },
  logo: {
    height: 64,
  },
  title: {
    flexGrow: 1,
  },
  main: {
    flex: 1,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: (props: { isMediumDown: boolean }) => (props.isMediumDown ? 0 : drawerWidth),
    width: (props: { isMediumDown: boolean }) => (props.isMediumDown ? '100%' : `calc(100% - ${drawerWidth}px)`),
  },
  content: {
    display: 'flex',
    height: '100%',
  },
  navSection: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  navSpacing: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  wifiIcon: {
    fontSize: '1.7rem',
    marginRight: '12px',
    marginTop: theme.spacing(0.6),
  },
  drawerPaper: {
    position: 'relative',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: theme.mixins.toolbar,
}))
const drawerWidth = 275

const App = () => {
  const theme = useTheme()
  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles({ isMediumDown })
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const { instance, inProgress, accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const navigate = useNavigate()

  const isReady = useAppSelector((state) => state.auth.isReady)
  const accessToken = useAppSelector((state) => state.auth.accessToken)
  const name = useAppSelector((state) => state.auth.name)
  const novuSubscriberInfo = useAppSelector((state) => state.auth.novuSubscriberInfo)
  const organization = useAppSelector((state) => state.auth.organization)
  const addressCandidates = useAppSelector((state) => state.auth.addressCandidate)

  const [isConnected, setIsConnected] = useState(false)
  const [refreshAlert, setRefreshAlert] = useState(false)
  const [secondsUntilRefresh, setSecondsUntilRefresh] = useState(120)

  useEffect(() => {
    const checkConnectivity = async () => {
      setIsConnected(false)
      if (addressCandidates)
        for (const address of addressCandidates) {
          try {
            const url = `http://${encodeURIComponent(address.hostAddress)}:49996/api/version`
            const response = await fetch(url)
            if (response.ok) {
              setIsConnected(true)
              dispatch(setWorkingAddress(address.hostAddress))
              return
            }
          } catch (error) {
            console.error(`Error connecting to ${address.hostAddress}: `, error)
          }
        }
    }

    checkConnectivity()
  }, [addressCandidates, dispatch])

  useEffect(() => {
    const checkForNewAppVersion = async () => {
      try {
        const host = `${window.location.protocol}//${window.location.hostname}`
        const response = await fetch(`${host}`)
        const text = await response.text()
        const remoteScripts = Array.from(text.matchAll(/<script.*?src="(.*?\.chunk\.js)".*?>/g))
          .map((match) => match[1])
          .map((script) => script.split('/').pop())
          .slice(-2)
        if (remoteScripts.length < 2) return
        const localScripts = Array.from(document.getElementsByTagName('script'))
          .map((script) => script.src.split('/').pop())
          .filter(Boolean)
          .slice(-2)
        if (localScripts.length < 2) return
        const scriptsDiffer = remoteScripts.some((remoteScript, index) => remoteScript !== localScripts[index])
        if (scriptsDiffer) {
          setRefreshAlert(true)
          const intervalId = setInterval(() => {
            setSecondsUntilRefresh((prev) => {
              if (prev > 1) {
                return prev - 1
              } else {
                clearInterval(intervalId)
                window.location.reload()
                return 0
              }
            })
          }, 1000)
        }
      } catch (err) {
        console.error('Error checking for new app version:', err)
      }
    }
    checkForNewAppVersion()
    const intervalId = setInterval(checkForNewAppVersion, 180000)
    return () => clearInterval(intervalId)
  }, [])

  const handleRefreshNow = () => {
    window.location.reload()
  }

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest).catch((e: unknown) => {
        console.error(e)
      })
    }
    if (isAuthenticated && inProgress === InteractionStatus.None) {
      const account = accounts[0]
      const request = {
        ...loginRequest,
        account,
      }

      instance
        .acquireTokenSilent(request)
        .then((response: AuthenticationResult) => {
          dispatch(setAccessToken(response.accessToken))
          dispatch(setAuthenticated(true))
          dispatch(setName(account.name))
          dispatch(setReady(true))
        })
        .catch(() => {
          instance.acquireTokenRedirect(request)
        })
    }
  }, [isAuthenticated, inProgress, accounts, instance, dispatch])

  useEffect(() => {
    if (accessToken) {
      const fetchUserData = async () => {
        try {
          const [novuResponse, licensesResponse, userDetailsResponse, organizationResponse, addressCandidateResponse] =
            await Promise.all([
              fetchNovuSubscriberInfo(accessToken),
              fetchProductLicenses(accessToken),
              fetchUserDetails(accessToken),
              fetchOrganization(accessToken),
              fetchAddressCandidate(accessToken),
            ])

          if (!organizationResponse.isProductViewActivated) {
            navigate('/reports')
          }

          dispatch(setNovuSubscriberInfo(novuResponse))
          dispatch(setProductLicenses(licensesResponse))
          dispatch(setUserDetails(userDetailsResponse))
          dispatch(setOrganization(organizationResponse))
          dispatch(setAddressCandidate(addressCandidateResponse))
          dispatch(setReady(true))

          if (!userDetailsResponse?.language) {
            i18n.changeLanguage(i18n.language)
            updateUserLanguage(accessToken, i18n.language)
          } else {
            i18n.changeLanguage(userDetailsResponse.language)
          }
        } catch (error) {
          console.error('Failed to fetch user data:', error)
        }
      }

      fetchUserData()
    }
  }, [accessToken, dispatch])

  const handleLogout = () => {
    instance.logoutRedirect({ account: accounts[0] })
    dispatch(clearAuth())
  }

  const menuItems = [
    {
      title: t('otherLanguage'),
      action() {
        const newLanguage = i18n.language === 'fr' ? 'en' : 'fr'
        i18n.changeLanguage(newLanguage)
        if (accessToken) updateUserLanguage(accessToken, newLanguage)
      },
    },
    { title: t('logout'), action: handleLogout },
  ]
  if (organization?.isProductViewActivated) {
    menuItems.push({
      title: t('userSettings'),
      action: () => navigate('/UserSettings'),
    })
  }
  const [mobileOpen, setMobileOpen] = useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  if (!isReady) {
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Fade
          in
          style={{
            transitionDelay: '800ms',
          }}
          unmountOnExit
        >
          <CircularProgress color="secondary" size={100} />
        </Fade>
      </Box>
    )
  }

  return (
    <>
      <AppBar position="relative" color="secondary" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <div className={classes.navSection}>
            <IconButton
              sx={{ display: isMediumDown ? 'block' : 'none' }}
              edge="start"
              className={classes.navSpacing}
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <img className={classes.logo} src={PMPLogo} alt="PMP Logo" />
          </div>
          <div className={classes.navSection}>
            <Box>
              <WifiIcon className={classes.wifiIcon} sx={{ color: isConnected ? green[500] : red[500] }} />
            </Box>
            {novuSubscriberInfo?.subscriberId && novuSubscriberInfo?.subscriberHash && (
              <NovuProvider
                subscriberId={novuSubscriberInfo.subscriberId}
                applicationIdentifier={`${novuAppIdentifier}`}
                subscriberHash={novuSubscriberInfo.subscriberHash}
              >
                <PopoverNotificationCenter colorScheme={'dark'}>
                  {({ unseenCount }) => <NotificationBell unseenCount={unseenCount} />}
                </PopoverNotificationCenter>
              </NovuProvider>
            )}
            {isAuthenticated && (
              <>
                <div className={classes.navSpacing}>{name}</div>
                <PopoverMenu menuItems={menuItems} />
              </>
            )}
            {!isAuthenticated && t('notConnected')}
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.content}>
        <nav>
          <Drawer
            variant={isMediumDown ? 'temporary' : 'permanent'}
            open={!isMediumDown || mobileOpen}
            onClose={handleDrawerToggle}
            sx={{
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                marginTop: '64px',
                height: 'calc(100vh - 64px)',
              },
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
              <ReportsContainer />
              {organization?.isProductViewActivated ? <ProductsContainer /> : <></>}
            </Box>
            <Box>
              <NeedHelp />
            </Box>
          </Drawer>
        </nav>
        <main className={classes.main}>
          <Routes>
            <Route path="/reports/:workspaceId/:reportId" element={<ReportViewerWrapper />} />
            <Route path="/reports" element={<ReportPlaceholder />} />
            <Route path="/WeSaw" element={<WeSawLayout isConnected={isConnected} />} />
            <Route path="/WePlane" element={<WePlaneLayout />} />
            <Route path="/WeTrack" element={<WeTrackLayout />} />
            <Route path="/WeSchedule" element={<WeScheduleLayout />} />
            <Route path="/WeDry" element={<WeDryLayout />} />
            {organization?.isProductViewActivated && <Route path="/UserSettings" element={<UserSettingsLayout />} />}
          </Routes>
        </main>
      </div>
      <Snackbar
        open={refreshAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ position: 'fixed', top: 0, zIndex: theme.zIndex.drawer + 1 }}
      >
        <Alert
          severity="info"
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'white',
          }}
        >
          <Box
            sx={{
              marginBottom: 1,
            }}
          >
            {t('updateAlert.newVersion')}
            {secondsUntilRefresh}
            {t('updateAlert.seconds')}
          </Box>

          <Box sx={{ marginLeft: 'auto' }}>
            <Button variant="contained" color="primary" onClick={handleRefreshNow}>
              Refresh Now
            </Button>
          </Box>
        </Alert>
      </Snackbar>
    </>
  )
}

export default App
