import InfoIcon from '@mui/icons-material/Info'
import { Box, Typography, Button, ListItem, List, Tooltip, IconButton, Modal } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { fetchDashboards } from '../../../services/apiService'
import { useAppSelector } from '../../../store/hooks'
import { Dashboard } from '../../../types'

const HorizontalList = styled(List)(() => ({
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'auto',
  listStyle: 'none',
  padding: 0,
}))

const DashboardItem = styled(Box)(() => ({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  width: '192px',
  height: 'auto',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: '#c8c9cb',
  },
  padding: '10px',
  paddingBottom: '10px',
  paddingTop: '10px',
}))

const Dashboards: React.FC<{ isConnected: boolean }> = ({ isConnected }) => {
  const accessToken = useAppSelector((state) => state.auth.accessToken)
  const serverIP = useAppSelector((state) => state.auth.workingAddress)
  const [dashboards, setDashboards] = useState<Dashboard[]>([])
  const [selectedImage, setSelectedImage] = useState<string | undefined>(undefined)
  const [currentDashboardId, setCurrentDashboardId] = useState<string | undefined>(undefined)

  useEffect(() => {
    const getDashboards = async () => {
      if (accessToken) {
        try {
          const fetchedDashboards = await fetchDashboards(accessToken)
          const sortedDashboards = fetchedDashboards.sort((a, b) => a.title.localeCompare(b.title))
          const updatedDashboards = sortedDashboards.map((dashboard) => ({
            ...dashboard,
            urlToDashboard: serverIP ? replaceServerIP(dashboard.urlToDashboard, serverIP) : dashboard.urlToDashboard,
            thumbnail: dashboard.thumbnail,
          }))
          setDashboards(updatedDashboards)
          if (selectedImage && currentDashboardId) {
            const currentDashboard = updatedDashboards.find((dashboard) => dashboard.id === currentDashboardId)
            if (currentDashboard) {
              const newImage = base64ToImage(currentDashboard.thumbnail)
              setSelectedImage(newImage)
            }
          }
        } catch (error) {
          console.error('Failed to fetch dashboards:', error)
        }
      }
    }
    getDashboards()

    const interval = setInterval(() => {
      getDashboards()
    }, 300000) // Fetch every 5 minutes

    return () => clearInterval(interval)
  }, [accessToken, serverIP, selectedImage, currentDashboardId])

  const base64ToImage = (base64: string) => {
    return `data:image/jpeg;base64,${base64}`
  }

  const replaceServerIP = (url: string, newIP: string) => {
    return url.replace(/http:\/\/[^:]+/, `http://${newIP}`)
  }

  const handleImageClick = (base64: string, id: string) => {
    setSelectedImage(base64ToImage(base64))
    setCurrentDashboardId(id)
  }

  const handleClose = () => {
    setSelectedImage(undefined)
    setCurrentDashboardId(undefined)
  }

  const { t } = useTranslation()

  return (
    <Box sx={{ display: 'inline', flexDirection: 'rows', border: '1px solid #ccc' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', padding: 1, paddingBottom: 0 }}>
        <Typography variant="h6">{t('dashboards.dashboards')}</Typography>
        <Tooltip title={t('dashboards.refreshEvery5Minutes')} placement="right">
          <IconButton size="small">
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'flex-start' }}>
        <HorizontalList>
          {dashboards.map((dashboard) => (
            <ListItem key={dashboard.id} sx={{ justifyContent: 'center', display: 'flex' }}>
              <Tooltip title={dashboard.title} placement="top">
                <Button
                  href={isConnected ? dashboard.urlToDashboard : 'javascript:void(0)'}
                  target={isConnected ? '_blank' : undefined}
                  rel="noopener noreferrer"
                  sx={{ padding: 0, textTransform: 'none', display: 'block' }}
                  disabled={!isConnected}
                >
                  <DashboardItem
                    sx={{
                      color: !isConnected ? 'text.disabled' : 'text.primary',
                      pointerEvents: !isConnected ? 'auto' : 'auto',
                    }}
                  >
                    <img
                      src={base64ToImage(dashboard.thumbnail)}
                      alt={dashboard.title}
                      style={{
                        width: '100%',
                        cursor: !isConnected ? 'zoom-in' : 'pointer',
                        borderRadius: '6px',
                      }}
                      onClick={() => handleImageClick(dashboard.thumbnail, dashboard.id)}
                    />
                    <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {dashboard.title}
                      </Typography>
                    </Box>
                  </DashboardItem>
                </Button>
              </Tooltip>
            </ListItem>
          ))}
        </HorizontalList>
        {!isConnected && selectedImage ? (
          <Modal
            open={!!selectedImage}
            onClose={handleClose}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Box
              sx={{
                maxWidth: '90%',
                maxHeight: '90%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <img
                src={selectedImage}
                alt="Full size"
                style={{
                  width: '90%',
                  maxWidth: '90%',
                  maxHeight: '90%',
                  objectFit: 'contain',
                  cursor: 'zoom-out',
                }}
                onClick={handleClose}
              />
            </Box>
          </Modal>
        ) : null}
      </Box>
    </Box>
  )
}

export default Dashboards
